<template>
  <div class="workflows-in-progress minimal" v-if="minimal">
    <Tag
      v-if="workflows"
      severity="info"
      class="p-tag-inverted"
    >
      {{ workflowsInProgress.length }} 
      <span v-if="showRunningWorkflows" class="p-ml-1">
       running workflow{{ workflowsInProgress.length === 1 ? "" : "s" }}
      </span>
    </Tag>
    <Skeleton v-else class="foreground-skeleton" />
  </div>
  <div class="workflows-in-progress" :class="{ collapsed: collapsed }" v-else>
    <ul class="workflows" v-if="workflowsInProgress.length > 0">
      <li
        class="workflow"
        v-for="workflow in workflowsInProgress.slice(0, collapsed ? 1 : 2)"
        :key="workflow.id"
      >
        <span class="label">
          {{ workflow.workflowDisplayName }}
          <h5 class="event-name">{{ eventDisplayName(workflow) }}</h5>
        </span>
        <span class="timeline">
          <Timeline
            layout="horizontal"
            :labels="false"
            :processedEvents="workflow.data.processedEvents"
            :pendingEvent="workflow.pendingEvent"
          />
          <KVCard label="Last" align="right" inline v-if="workflow.pendingEvent">
            <Date isUtc hour>{{ workflow.pendingEvent.assignedDateUtc }}</Date>
          </KVCard>
        </span>
      </li>
    </ul>
    <div class="workflows-remaining" v-if="collapsed && workflowsInProgress.length > 1">
      <strong v-if="collapsed">+{{ workflowsInProgress.length - 1 }}</strong>
    </div>
    <div class="workflows-remaining" v-else-if="!collapsed && workflowsInProgress.length > 2">
      {{ workflowsInProgress.length - 2 }} more workflows
    </div>
    <Skeleton v-if="!workflowsInProgress" class="foreground-skeleton" />
    <div class="no-active-workflows" v-else-if="workflowsInProgress.length === 0">
      No active workflows
      <Skeleton v-if="!workflows" class="foreground-skeleton" />
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import Timeline from "@/components/elements/Timeline";
import KVCard from "@/components/elements/KeyValueCard";
import Date from "@/components/elements/Date";
import workflowMixins from "@/mixins/workflow";

export default {
  name: "WorkflowsInProgress",
  mixins: [workflowMixins],
  components: {
    Timeline,
    KVCard,
    Date,
    Skeleton,
    Tag,
  },
  props: {
    workflows: Array,
    collapsed: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: true,
    },
    showRunningWorkflows: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    workflowsInProgress() {
      return this.workflows ? this.workflows.filter(workflow => !workflow.completeTime) : [];
    },
  },
  methods: {
    eventDisplayName(workflow) {
      return this.workflowEventDisplayName({
        workflowDefinitionId: workflow.workflowDefinitionId,
        isFinalTermsheet: workflow.data.isFinalTermsheet,
        isDecrease: workflow.data.isDecrease,
        paymentFlowDescription: workflow.data.paymentFlowDescription,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.workflows-in-progress {
  flex: 1;
  position: relative;
  min-height: 1.75em;

  &.collapsed {
    display: flex;
    font-weight: 600;

    .event-name,
    .timeline {
      display: none;
    }

    .workflows {
      display: flex;
      flex: 1;

      .workflow {
        flex: 1;
        justify-content: center;
        margin: 0 4px 0 0;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        line-height: 1em;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .workflows-remaining {
      margin-left: 4px;
      padding-left: 0.75em;
      padding-right: 0.75em;
    }
    .no-active-workflows {
      flex: 1;
    }
  }

  &.minimal {
    white-space: nowrap;

    .p-tag {
      font-size: 1rem;
      border-radius: var(--border-radius-sm);
    }
  }
}

.workflows {
  margin: 0;
}

.workflow,
.workflows-remaining,
.no-active-workflows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--card-border-color);
  border-radius: var(--border-radius-sm);
}
.workflow {
  padding: 0.75rem;
  margin-bottom: 4px;

  .label {
    font-size: 1rem;
    white-space: break-spaces;

    h5 {
      margin: 0;
      font-size: 1em;
    }
  }
  .timeline {
    width: 50%;
    text-align: right;

    .p-timeline {
      margin-bottom: 0;
    }

    .date {
      font-size: 0.7777778em;
    }
  }
}
.workflows-remaining,
.no-active-workflows {
  justify-content: center;
  padding: 0.35rem;
  font-size: 0.777778em;
  color: var(--text-color-secondary);
}
</style>
